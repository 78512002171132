.noResultsContainer {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.noResultsContainer > img {
  width: 184px;
}

.spinnerContainer {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
