.dashboardContainer {

  :global(.recharts-brush-texts) {
    display: none;
  }

  :global(.recharts-brush rect) {
    rx: 4;
  }

  :global(rect.recharts-brush-slide) {
    rx: 0;
  }

  :global(.recharts-brush-traveller ) {
    display: none;
  }
}


.customTooltip {
  background: #616161;
  color: #ffffff;
  border: none;
  padding: 8px;
  opacity: 0.9;
  border-radius: 4px;
  max-width: 400px;
}

.customTooltip__label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 16px;
}

.customTooltip__itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.customTooltip__itemsContainer__item {
  font-size: 14px;
  font-weight: 400;
}

.customTooltip__itemsContainer__itemContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.customTooltip__itemsContainer__itemPercentage {
  color: #bdbdbd;
}

.customLegend {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.customLegend__item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.customLegend__itemName {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
}
